import React from 'react';
import { ReactComponent as MagicWand } from '../loghi/magic-wand.svg';
import { Button } from 'react-bootstrap';


function Intro() {
  
    return (
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <MagicWand className="w-75 mb-3" />
              <h2>ACCELERATION PROGRAM FOR DIGITAL FAST GROWING SUSTAINABILITY STARTUPS</h2>
              <p className="py-4">We strongly believe startups’ ability to transform business is on the gear and startups will lead the creation of long term impact solution on global challenges</p>
              <Button variant="primary" size="lg" href="https://www.f6s.com/magicwandromeaccelerationprogram/apply">Apply</Button>
            </div>
            <div className="col-md-6 investments">
              <div className="general-tit-box">Investment</div>
              <div className="row values">
                <div className="col-md-6">
                  <div className="value">€175k</div>
                  per startup
                </div>
                <div className="col-md-6">
                  <div className="value">3</div>
                  months acceleration program
                </div>
              </div>
              <picture>
									  <source srcset="/img/gearsx2.png 2x,
						             /img/gearsx3.png 3x"/>
									  <img src="/img/gears.png" className="d-none d-md-block pb-5" alt="Gears"/>
							</picture>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
  
  export default Intro;