import React from 'react';


function Highlights() {
  
    return (
      <div className="Highlights container padding-box">
        <div className="row">
          <div className="col-md-6 align-self-center pr-5">
            <h2 className="text-uppercase mb-5">
              Have a look at some highlights from our previous editions
            </h2>
          </div>
          <div className="col-md-6">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src="https://player.vimeo.com/video/465281543" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Highlights;