import React from 'react';
import { Button } from 'react-bootstrap';


function Bannercontact() {
  
    return (
      <div className="container padding-box">
        <div className="Bannercontact row p-5">
          <div className="col-12 text-center">
            <h2 className="text-uppercase">Any questions? Meet our team </h2>
            <p>Contact us to book a meeting at <a href="mailto:magicwand@digitalmagics.com">magicwand@digitalmagics.com</a></p>
            <Button variant="danger" size="lg" href="mailto:magicwand@digitalmagics.com">Contact us</Button>
          </div>
        </div>
      </div>
    );
  }
  
  export default Bannercontact;