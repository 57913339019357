import React from 'react';


function Greenbanner() {
  
    return (
      <div className="greenbanner">
        <div className="container">
          <div className="row">
            <div className="col-12 content-box">
              <div className="col-lg-8">
                <div className="tit-box">
                We are looking for startups operating in Italy and Europe developing <strong>sustainable</strong> digital solutions across different industries.
                </div>
                <p>
                  In this Magic Wand edition, we offer, together with our corporate partners a high level of expertise in the following fields: <strong>energy transition, HR Tech, Educational Technologies, Aerospace, Digital communications, Digital for health and wellness.</strong>
                  <br/>The accelerator team will provide founders with hands-on support to help them understand the fundamentals of their business, how to take on the market and get ready for investment. 
                  <br/>Startups participating in the program will receive an investment of 175 K€ in cash and services by Digital Magics and Innova Venture, together with access to qualified discussion with the program’s corporate partners.
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
  
  export default Greenbanner;