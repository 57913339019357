import React from 'react';
import { Link, Events, animateScroll as scroll, scroller } from 'react-scroll'
import { Navbar, Nav } from 'react-bootstrap';
import { ReactComponent as DigitalMagic } from '../loghi/digital-magic.svg';
import { ReactComponent as Regione } from '../loghi/regione-lazio.svg';
import { ReactComponent as Fers } from '../loghi/fesr-full.svg';


class Headermenu extends React.Component {

    constructor(props) {
      super(props);
      this.scrollToTop = this.scrollToTop.bind(this);
    }
  
    componentDidMount() {
  
      Events.scrollEvent.register('begin', function () {
        console.log("begin", arguments);
      });
  
      Events.scrollEvent.register('end', function () {
        console.log("end", arguments);
      });
  
    }
    scrollToTop() {
      scroll.scrollToTop();
    }
    scrollTo() {
      scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }
    scrollToWithContainer() {
  
      let goToContainer = new Promise((resolve, reject) => {
  
        Events.scrollEvent.register('end', () => {
          resolve();
          Events.scrollEvent.remove('end');
        });
  
        scroller.scrollTo('scroll-container', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        });
  
      });
  
      goToContainer.then(() =>
        scroller.scrollTo('scroll-container-second-element', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          containerId: 'scroll-container'
        }));
    }
    componentWillUnmount() {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    }

    
    componentDidMount() {
      window.addEventListener("scroll", this.resizeHeaderOnScroll);
    }
    resizeHeaderOnScroll() {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 200,
        headerEl = document.getElementById("js-header");
      if (distanceY > shrinkOn) {
        headerEl.classList.add("smaller");
      } else {
        headerEl.classList.remove("smaller");
      }
    }
    

    render() {
      return (
        <div className="header" id="js-header"> 
            <div className="logo-section pt-2">
              <div className="container-fluid">
                  <div className="row justify-content-center justify-content-md-between">
                      <div className="col-md-2 col-7 logo-small">
                        <DigitalMagic className="w-50" />
                      </div>
                      <div className="col-md-2 col-7">
                          <Fers className="fers-logo"/>
                      </div>
                      <div className="col-md-2 col-9 d-none d-md-block text-right">
                        <div className="d-flex align-items-center justify-content-end h-100">
                          <Regione className="w-50"/>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="menu-section pt-4">
              <div className="container-fluid">
              <Navbar bg="dark" variant="dark" className="row justify-content-center justify-content-md-between flex-wrap">
                  <div className="col-md-2 col-6 pl-md-0 pl-3"><Navbar.Brand><DigitalMagic className="w-75" /></Navbar.Brand></div>
                  <Nav className="ml-auto flex-wrap justify-content-center justify-content-md-end">
                      <Nav.Link><Link activeClass="active" to="intro" spy={true} smooth={true} duration={500}>Home</Link></Nav.Link>
                      <Nav.Link><Link activeClass="active" to="timeline" spy={true} smooth={true} duration={500}>Timeline</Link></Nav.Link>
                      <Nav.Link><Link activeClass="active" to="focus" spy={true} smooth={true} duration={500}>Focus Areas</Link></Nav.Link>
                      <Nav.Link><Link activeClass="active" to="whyjoin" spy={true} smooth={true} duration={500}>Why Join the Program?</Link></Nav.Link>
                      <Nav.Link><Link activeClass="active" to="startups" spy={true} smooth={true} duration={500}>Testimonial</Link></Nav.Link>
                      <Nav.Link><Link activeClass="active" to="bannercontanct" spy={true} smooth={true} duration={500}>Contact</Link></Nav.Link>
                      <Nav.Link><Link activeClass="active" to="partners" spy={true} smooth={true} duration={500}>Partners</Link></Nav.Link>
                  </Nav>
              </Navbar>
              </div>
            </div>
            
        </div>
      );
    }
  };
  
  function Header() {
    
    return (
      <div>
        <Headermenu/>
      </div>
    );
  }
  
  export default Header;