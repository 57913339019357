import React from 'react';
import "./styles/main.scss"
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import  Intro  from './components/intro';
import  Bannercontact  from './components/bannercontact';
import  Categories  from './components/categories';
import  Focus  from './components/focus';
import  Footer  from './components/footer';
import  Greenbanner  from './components/greenbanner';
import  Highlights  from './components/highlights';
import  Partners  from './components/partners';
import  Startups  from './components/stratups';
import  Timeline  from './components/timeline';
import  Whyjoin  from './components/whyjoin';
import  Header  from './components/header';


class Homepage extends React.Component {

  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {

    Events.scrollEvent.register('begin', function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function () {
      console.log("end", arguments);
    });

  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }
  scrollToWithContainer() {

    let goToContainer = new Promise((resolve, reject) => {

      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });

      scroller.scrollTo('scroll-container', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });

    });

    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      }));
  }
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  render() {
    return (
      <div>
          <Header/>

          <Element name="intro" className="element" >
            <Intro/>
          </Element>

          <Element name="categories" className="element" >
            <Categories/>
          </Element>

          <Element name="greenbanner" className="element" >
            <Greenbanner/>
          </Element>

          <Element name="timeline" className="element" >
            <Timeline/>
          </Element>

          <Element name="focus" className="element" >
            <Focus/>
          </Element>


          <Element name="whyjoin" className="element" >
            <Whyjoin/>
          </Element>

          <Element name="startups" className="element" >
            <Startups/>
          </Element>
          <div id="box4">
            <Element name="bannercontanct" className="element" >
              <Bannercontact />
            </Element>

            <Element name="highlights" className="element" >
              <Highlights />
            </Element>

            <Element name="partners" className="element" >
              <Partners />
            </Element>
          </div>

          <Footer/>

          <Link onClick={this.scrollToTop} className="buttonTop">To the top!</Link>
      </div>
    );
  }
};

function App() {
  
  return (
    <div className="App">
      <Homepage/>
    </div>
  );
}

export default App;
