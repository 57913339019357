import React from 'react';
import { ReactComponent as DigitalMagic } from '../loghi/digital-magic.svg';
import { ReactComponent as MagicWand } from '../loghi/magic-wand.svg';
//import ModalCall from './modal/open-call';
//import ModalContact from './modal/contact';
//import ModalPrivacy from './modal/privacy-policy';


function Footer() {
  
    return (
      <footer className="Footer container-fluid">
        <div className="row justify-content-start pt-2 text-center text-md-left">
          <div className="col-md-3 col-12 dg">
            <DigitalMagic className="w-50" />
          </div>
          <div className="col-md-6 col-12 text-center">
            <MagicWand className="w-50 mt-md-0 mt-3"/>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-md-4 copyright text-center text-md-left">
            © 2020 Digital Magics, all rights reserved.
          </div>
          <div className="col-md-8 text-center text-md-right link">
            <a class="btn-link" href="https://www.f6s.com/magicwandromeaccelerationprogram/apply">Open Call</a>
            <a class="btn-link" href="https://www.f6s.com/magicwandromeaccelerationprogram/apply">Privacy policy</a>
            <a class="btn-link" href="mailto:magicwand@digitalmagics.com">Contact us</a>
            {/* <ModalCall buttonLabel="Open Call" />
            <ModalPrivacy buttonLabel="Privacy policy" /> 
            <ModalContact buttonLabel="Contact us" />  */}
          </div>
        </div>
      </footer>
    );
  }
  
  export default Footer;