import React from 'react';


function CarouselSanpaolo() {

  return (
     <>
      <div className="container-image">
        <div className="logo">
          <img src="/img/loghi-starups/Intesa_Sanpaolo.png" alt="Intesa San Paolo" />
        </div>
      </div>
      <p>
        "It's a pleasure, for those like me who participate in similar programs offered by international competitors, to say that Magic Wand has been a success. An all-Italian initiative that, thanks to the professionalism and commitment of the Digital Magics team, has produced valuable results that are absolutely in line with international standards.”
      </p>
      <p className="name">
        <strong>Lidia Varesio</strong>
        Innovation Analyst - Intesa San Paolo Innovation Department
      </p>
    </>
  );
}

export default CarouselSanpaolo;