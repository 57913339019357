import React from 'react';


function Timeline() {
  
    return (
      <div className="timeline">
        <div className="container">
          <div className="row">
            <div className="col-12 pb-4">
              <h2 className="head-box">TIMELINE</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 event">
              <div className="calendar">
                <div className="row">
                  <div className="data">14.09.2020</div>
                  <div className="dash"></div>
                </div>
              </div>
              <div className="tit">
                Open Call
              </div>
            </div>

            <div className="col-md-3 event">
              <div className="calendar">
                <div className="row">
                  <div className="data">19.10.2020</div>
                  <div className="dash"></div>
                </div>
              </div>
              <div className="tit">
                Call Closing
              </div>
            </div>

            <div className="col-md-3 event">
              <div className="calendar">
                <div className="row">
                  <div className="data">13.11.2020</div>
                  <div className="dash"></div>
                </div>
              </div>
              <div className="tit">
              Pitch Day & Startups Selection <br/>(10 Startups)
              </div>
            </div>

            <div className="col-md-3 event">
              <div className="calendar">
                <div className="row">
                  <div className="data">16.11.2020</div>
                  <div className="dash"></div>
                </div>
              </div>
              <div className="tit">
              Program Start - Discovery Phase
              </div>
            </div>

            <div className="col-md-3 event">
              <div className="calendar">
                <div className="row">
                  <div className="data">13.01.2021</div>
                  <div className="dash"></div>
                </div>
              </div>
              <div className="tit">
              Selection Gate<br/>(6 Startups) 
              </div>
            </div>

            <div className="col-md-3 event">
              <div className="calendar">
                <div className="row">
                  <div className="data">18.01.2021</div>
                  <div className="dash"></div>
                </div>
              </div>
              <div className="tit">
                Start – Validation Phase 
              </div>
            </div>

            <div className="col-md-3 event">
              <div className="calendar">
                <div className="row">
                  <div className="data">22.02.2021 </div>
                  <div className="dash"></div>
                </div>
              </div>
              <div className="tit">
                Investor Day
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
  
  export default Timeline;