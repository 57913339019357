import React from 'react';
import { Button } from 'react-bootstrap';

function Categories() {
  
    return (
      <div className="categories-section">
        <div className="container">
          <div className="row align-items-end pb-3">
            <div className="col-lg-6">
              <div className="tit-box">
              LET’S <strong>RE-IMAGINE</strong><br/>
              TOGETHER THE <strong>CITIES</strong>,<br/>
              THE <strong>WORKPLACES</strong>,<br/>
              AND <strong>HOW PEOPLE</strong><br/>
              WILL <strong>SOCIALIZE</strong>
              </div>
              <p>
                After three successful editions, this is the <strong>fourth batch</strong>, 
                specifically dedicated to <strong>startups based</strong>, <strong>or willing to move, in Lazio region</strong>. 
                The Acceleration program’s focus is related to <strong>Sustainability Solutions</strong>, 
                embracing the current initiatives supporting economic restart, 
                after covid19 pandemic crisis.<br/>Thanks to the partnership between <strong>Digital Magics </strong>
                and <strong>Lazio Innova</strong>, <strong>Innova Venture</strong> fund will co-invest in program startups.
              </p>
            </div>
            <div className="col-lg-6">
                <picture>
									  <source srcset="/img/image-fascia-2x2.png 2x,
						             /img/image-fascia-2x3.png 3x"/>
									  <img src="/img/image-fascia-2.png" alt="Categories" className="image-section"/>
								</picture>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="general-tit-box">Main Categories</div>
            </div>
          </div>
          <div className="row btn-section">
            <Button variant="outline-info" className="square-btn flex-fill"  disabled size="lg">Energy</Button>
            <Button variant="outline-info" className="square-btn flex-fill"  disabled size="lg">Medtech</Button>
            <Button variant="outline-info" className="square-btn flex-fill"  disabled size="lg">Edtech</Button>
            <Button variant="outline-info" className="square-btn flex-fill"  disabled size="lg">HRtech</Button>
            <Button variant="outline-info" className="square-btn flex-fill"  disabled size="lg">Transportation</Button>
            <Button variant="outline-info" className="square-btn flex-fill"  disabled size="lg">Security</Button>
            <Button variant="outline-info" className="square-btn flex-fill"  disabled size="lg">Agrytech</Button>
          </div>
          <div className="row btn-section">
            <Button variant="outline-info" className="square-btn flex-fill"  disabled size="lg">Infrastructure  -  Enabling Technologies  -  Cross Service</Button>
          </div>
        </div>
      </div>
    );
  }
  
  export default Categories;