import React from 'react';


function CarouselMoneyMour() {

  return (
    <>
      <div className="container-image">
        <div className="logo">
          <img src="/img/loghi-starups/moneymour.png" alt="Intesa San Paolo" />
        </div>
      </div>
      <p>
        Those who establish a start-up are lucky in that they have one of the best jobs in the world, albeit extremely difficult. Moneymour has found a powerful ally in Magic Wand. The Digital Magics Team answers every question: "Do you want to propose a partnership to a particular financial institution?" "We'll organise a meeting with the CEO and the Head of Risk" "Do you want to discuss the privacy policy with a lawyer? "We'll get the lawyers engaged"
      </p>
      <p className="name">
        <strong>Giacomo De Lorenzo</strong>
        Moneymour Founder
      </p>
    </>
  );
}

export default CarouselMoneyMour;