import React, { Component } from "react";
import Slider from "react-slick";

import CarouselSanpaolo from './slider-startups/sanpaolo';
import CarouselMoneyMour from './slider-startups/moneymour';
import CarouselSisal from './slider-startups/sisal';

export default class Startups extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="Startups">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center px-5">
              <h2 className="text-uppercase pb-5">What our start-ups and partners say about us </h2>
                <Slider {...settings}>
                  <div className="carousel-item"><CarouselSanpaolo /></div>
                  <div className="carousel-item"><CarouselMoneyMour /></div>
                  <div className="carousel-item"><CarouselSisal /></div>
                </Slider>
            </div>
          </div>
        </div>        
      </div>
    );
  }
}
