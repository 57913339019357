import React from 'react';


function Focus() {
  
    return (
      <div className="focus">
        <div className="container">
          <div className="row">
              <div className="col-lg-6 pb-4">
                <h2 className="head-box">FOCUS AREA</h2>
                <p>
                  We work with startups in the MVP/prototype stage, first customer stage and scale-ups that operate in emerging and sustainable markets, mainly related to key industries and segments listed below. 
                </p>
              </div>
          </div>
          <div className="row">
            <div className="col-md-3 area">
              <div className="tit-box">ONE<br/>PLANET</div>
              <div className="box">
                <ul>
                  <li>Energy Transition</li>
                  <li>Circular Economy</li>
                  <li>Regenerative Impact</li>
                  <li>Renewable Energies</li>
                  <li>Power to X</li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 area">
              <div className="tit-box">Positive<br/>Organizations</div>
              <div className="box">
                <ul>
                  <li>Future of workforce/workplace</li>
                  <li>Mindful by design</li>
                  <li>Resilient organizations</li>
                  <li>Power to diversity</li>
                  <li>Caring leadership</li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 area">
              <div className="tit-box">Digital for health and wellness</div>
              <div className="box">
                <ul>
                  <li>Native digital stress reduction</li>
                  <li>Digital therapies</li>
                  <li>Health worker decision support digital systems</li>
                  <li>Targeted health communication via mobile</li>
                  <li>Digital tracking of patients</li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 area">
              <div className="tit-box">Digital<br/>from space</div>
              <div className="box">
                <ul>
                  <li>Communication <p>satellite, APR (Remote Piloted Aircraft), HAP (High Altitude Platform), GeoData, Data integrations</p></li>
                  <li>Security <p>earth and coasts monitoring, prevention systems, infrastructures control, land defence, tracking</p></li>
                  <li>Surveillance & Monitoring <p>space & naval traffic management, cities surveillance, green - health and pollution monitoring and prevention</p></li>
                  <li>Navigation Systems (integration) <p>GPS, satellite, inertial systems, navigation data processing</p></li>
                  <li>Aerospace <p>data collection positiong, Iot, constellations, machine learning, data mining, AI</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Focus;