import React from 'react';


function CarouselSisal() {

  return (
    <>
      <div className="container-image">
        <div className="logo">
          <img src="/img/loghi-starups/sisal.png" alt="Sisal" />
        </div>
      </div>
      <p>
        “Magic Wand is a highly valuable program that connects start-ups, Digital Magics and partner companies in a unique path of acceleration. It has allowed us to further explore the Fintech and Insurtech ecosystem, identifying and starting direct dialogue with some of the most promising start-ups”
      </p>
      <p className="name">
        <strong>Carlo Garuccio</strong>
        Head of SisalPay strategy
      </p>
    </>
  );
}

export default CarouselSisal;