import React from 'react';


function Whyjoin() {
  
    return (
      <div className="whyjoin">
        <div className="container">
          <div className="row">
              <div className="col-md-6 pb-4">
                <h2 className="head-box">WHY JOIN THE PROGRAM?</h2>
              </div>
          </div>
          <div className="row">
            <div className="col-md-6 why-box">
              <div className="tit-box">3-Months Acceleration Program</div> 
              <p>A program designed to help founders build successful scaling companies</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">Lectures & Masterclasses</div> 
              <p>Advisor-led lectures and masterclasses, covering all fundamentals from product economics to business development and fundraising</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">Mentorship & strategic advisory</div> 
              <p>Weekly sessions with the accelerator team offer hands-on support to understand how to take on the market and get ready for investment</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">Top Corporate Partners</div> 
              <p>Working tables with the program’s corporate partners</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">Co-Working Space</div> 
              <p> 2 co-working desks at DM offices in Rome plus the dynamic ‘Spazio Attivo’ Rome offices by Lazio Innova</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">Acceleration Investments</div> 
              <p> Up to 35K€ (DM) + 70K€ (IV) cash investment by DM for the 6 startups that enter the final phase, aimed at enhancing metrics and showing investors your startup is ready for fundraising</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">DM Follow On</div> 
              <p>DM + IV are looking forward to invest additional cash in each startup that closes a funding round</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">Regulatory Assessment</div> 
              <p>Performed by highly skilled and well-experienced lawyers to understand how to make your project compliant with any applicable regulation</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">Investor Day</div> 
              <p>A dedicated event for meeting active investors in the industry, corporate subjects, mentors and members of the press</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">Network and Community</div> 
              <p>DM supported the growth of +300 founders that can offer valuable advice and collaboration. You would also have access to the Lazio Innova startup community as well as to an extensive network of investors and industry experts</p>
            </div>
            <div className="col-md-6 why-box">
              <div className="tit-box">Perks & Discounts</div> 
              <p>Exclusive partner deals from leading providers in technologies, payments, legal & compliance, insurance, human resources</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Whyjoin;