import React from 'react';


function Partners() {
  
    return (
      <div className="Partners container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="text-uppercase mb-5">partners</h2>
            <img src="/img/lazio-innova.jpg" alt="Lazio innova"/>
          </div>
        </div>
      </div>
    );
  }
  
  export default Partners;